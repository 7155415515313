import { SVGProps } from "react";

const EditIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.32507 14.0449L2.23007 16.9649C2.18802 17.0773 2.1792 17.1994 2.20468 17.3166C2.23015 17.4339 2.28883 17.5413 2.37372 17.6261C2.4586 17.7109 2.5661 17.7695 2.68337 17.7948C2.80064 17.8202 2.92274 17.8112 3.03507 17.7691L5.95423 16.6741C6.28835 16.5489 6.59182 16.3537 6.84423 16.1016L15.3001 7.64572C15.3001 7.64572 15.0051 6.76155 14.1217 5.87739C13.2384 4.99405 12.3534 4.69905 12.3534 4.69905L3.89757 13.1549C3.64541 13.4073 3.45021 13.7108 3.32507 14.0449ZM13.5326 3.51989L14.6851 2.36739C14.8917 2.16072 15.1676 2.02905 15.4559 2.07739C15.8617 2.14405 16.4826 2.34572 17.0676 2.93155C17.6534 3.51739 17.8551 4.13739 17.9217 4.54322C17.9701 4.83155 17.8384 5.10739 17.6317 5.31405L16.4784 6.46655C16.4784 6.46655 16.1842 5.58322 15.3001 4.69989C14.4167 3.81489 13.5326 3.51989 13.5326 3.51989Z"
      fill={"none"}
    />
  </svg>
);

export default EditIcon;
