import { BackIcon } from "assets/svgs";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonButton from "../commonButton/Button";

const BackButton = ({ to, onBack }: { to?: string; onBack?: () => void }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <CommonButton
      startIcon={<BackIcon />}
      title={t("general.back")!}
      className="back"
      onClick={() => {
        onBack?.();
        navigate(-1);
      }}
      sx={{ height: "42px !important" }}
    />
  );
};

export default BackButton;
