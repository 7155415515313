import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import IconButton from "@mui/material/IconButton";
import { GridColumns, GridLocaleText } from "@mui/x-data-grid";
import { DeleteIcon } from "assets/svgs";
import TooltipBox from "components/common/tooltip/TooltipBox";
import { get } from "lodash";
import EditIcon from "../assets/EditIcon";
import { CustomIconButtonStyled } from "styles/global.style";

const branchId = localStorage.getItem("branchId");

export const getTableColumns = <TData extends { _id: string }>({
  numerate,
  columns,
  onEditColumn,
  onDeleteColumn,
  onSeenClick,
  deleteTitle,
  updateTitle,
}: {
  numerate?: boolean;
  columns: GridColumns<any>;
  onEditColumn?: (props?: any) => void;
  onDeleteColumn?: (props?: any) => void;
  onSeenClick?: (props?: any) => void;
  deleteTitle: string;
  updateTitle: string;
}): GridColumns<any> =>
  // @ts-ignore
  [
    numerate
      ? {
          field: "_number",
          headerName: "№",
          maxWidth: 60,
          minWidth: 60,
          align: "center",
          // sortable: true,
          filterable: false,
          headerAlign: "center",
          disableColumnMenu: true,
        }
      : undefined,
    ...columns,
    typeof onSeenClick === "function"
      ? {
          field: "_actions_seen",
          renderCell(row: any) {
            return (
              <IconButton
                onClick={(e) => {
                  // e.stopPropagation(); // !here may be bug🐛🪲
                  onSeenClick(row.row);
                }}
              >
                <RemoveRedEyeIcon />
              </IconButton>
            );
          },
          maxWidth: 60,
          align: "center",
          renderHeader: () => null,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
        }
      : undefined,
    typeof onEditColumn === "function"
      ? {
          field: "_actions_edit",
          renderCell(row: any) {
            return (
              <TooltipBox title={updateTitle}>
                <CustomIconButtonStyled
                  className="edit_icon_btn"
                  onClick={(e) => {
                    // e.stopPropagation(); // !here may be bug🐛🪲
                    onEditColumn(row.row);
                  }}
                >
                  <EditIcon />
                </CustomIconButtonStyled>
              </TooltipBox>
            );
          },
          maxWidth: 60,
          align: "center",
          renderHeader: () => null,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
        }
      : undefined,
    typeof onDeleteColumn === "function"
      ? {
          field: "_actions_delete",
          renderCell(row: any) {
            return (
              get(row, "row._id", "") !== branchId && (
                <TooltipBox title={deleteTitle}>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteColumn(row.row);
                    }}
                    className="delete_table_btn"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TooltipBox>
              )
            );
          },
          maxWidth: 60,
          align: "start",
          renderHeader: () => null,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
        }
      : undefined,
  ]
    .filter(Boolean) // get rid of undefined values
    .map((item, index) => ({
      disableColumnMenu: true,
      sortable: false,
      headerClassName: "table-header",
      // @ts-ignore
      flex: item?.flex ? item.flex : 1,
      minWidth: index === 0 ? 60 : 80,
      ...item,
    }));

export const localization: Partial<GridLocaleText> = {
  // columnMenuUnsort: ...
};

// ? digits and letters allowed no special characters no more than 1 spaces
export const isValidSearch = (search: string) => true;
// /^(([a-z\d]+\s)?[a-z\d]+)?$/i.test(search);
