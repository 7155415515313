import { SchoolIcon } from "assets/svgs";
import { CommonButton, TextInput } from "components";
import { useApiMutation } from "hooks/useApi/useApiHooks";
import Lottie from "lottie-react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ILoginData,
  setLoginData,
  setThemeState,
} from "store/reducers/LoginSlice";
import { useAppDispatch } from "store/storeHooks";
import LoginLeft1 from "../assets/loginLeft1.png";
import LoginLeft2 from "../assets/loginLeft2.png";
import LoginLeft3 from "../assets/loginLeft3.png";
import { LoginStyled } from "./Login.style";
import SchoolLogo from "assets/json/Animation - 1720694346953.json";

interface ILoginBody {
  login: string;
  password: string;
}
const Login = () => {
  const { control, handleSubmit } = useForm<ILoginBody>();
  const dis = useAppDispatch();
  const navigate = useNavigate();
  const url = window.location.href;
  const { t } = useTranslation();

  const { mutate, status } = useApiMutation<ILoginBody, ILoginData>(
    "sign-in",
    "post",
    {
      onSuccess(data) {
        dis(setLoginData(data.data));
        const theme = localStorage.getItem("theme");
        dis(setThemeState(theme || "light"));

        toast.success(t("toast_messages.success"));
        localStorage.setItem("token", data.data.token);
        localStorage.setItem(
          "branchId",
          data.data.branchEmployees?.[0]?.branchId
        );
        localStorage.setItem("employeeId", data.data._id);
        localStorage.setItem("i18nextLng", "uz");
        localStorage.setItem("schedule_show", "class");

        navigate("/schedule");
      },
    }
  );

  const submit = (data: ILoginBody) => {
    mutate(data);
  };
  return (
    <LoginStyled className="main-container">
      <div className="login-left">
        <div className="school_icon">
          {/* <img src={LoginSchool} alt="main" width={400} /> */}
          <Lottie
            animationData={SchoolLogo}
            loop={true}
            width={"40px"}
            height={"40px"}
          />
          {/* <h2>EDU SCHOOL</h2> */}
        </div>

        <p className="login-text-first">
          © All rights reserved. SCHOOL CRM - 2024
        </p>
        <p className="login-text-second">UNICAL SOLUTIONS</p>
      </div>

      <div className="login-right d-flex flex-column align-items-center justify-content-center">
        <img src={LoginLeft1} alt="loginleft1" className="loginleft1" />
        <img src={LoginLeft2} alt="loginleft2" className="loginleft2" />
        <img src={LoginLeft3} alt="loginleft3" className="loginleft3" />
        <form onSubmit={handleSubmit(submit)}>
          <div className="d-flex flex-column">
            <div className="text-center mb-1 logo_icon">
              {/* <img src={EducationImg} alt="education" width={80} /> */}
              <SchoolIcon />
            </div>
            <p className="text-center">LEARNING MANAGEMENT SYSTEM</p>
          </div>
          <main>
            <span>
              <TextInput
                control={control}
                name="login"
                type="text"
                label="Login"
                placeholder="Login"
              />
            </span>
            <span>
              <TextInput
                control={control}
                name="password"
                type="password"
                label="Password"
                placeholder="Password"
              />
            </span>
            <span>
              <CommonButton
                disabled={status === "loading"}
                title="Login"
                type="submit"
                status={status}
                className="main"
                sx={{
                  height: "42px !important",
                }}
              />
            </span>
          </main>
        </form>
      </div>
    </LoginStyled>
  );
};

export default Login;
